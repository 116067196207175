<template>
  <div class="mega-loading">

    <svg viewBox="-2000 -1000 4000 2000" class="mega-loading-loader" v-if="!finish && busy">
      <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path>
      <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px"></use>
    </svg>

    <span class="mega-loading-info" v-if="finish">No More Results :)</span>
  </div>
</template>

<script>
  export default {
    props: {
      disable: Boolean
    },
    data() {
      return {
        busy: false,
        finish: false
      }
    },
    computed: {
      screen() {
        return {
          top: document.documentElement.scrollTop,
          bottom: document.documentElement.scrollTop + window.innerHeight,
          left: document.documentElement.scrollLeft,
          right: document.documentElement.scrollLeft + window.innerWidth
        }
      },
      element() {
        return {
          top: this.$el.offsetTop,
          bottom: this.$el.offsetTop + this.$el.offsetHeight,
          left: this.$el.offsetLeft,
          right: this.$el.offsetLeft + this.$el.offsetWidth
        }
      }
    },
    watch: {},
    mounted() {
      this.checkScroll();

      window.addEventListener('scroll', this.checkScroll);
      window.addEventListener('resize', this.checkScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.checkScroll);
      window.removeEventListener('resize', this.checkScroll);
    },
    methods: {

      loaded(){
        this.busy = false;
      },

      refresh(){
        this.busy = false;
      },

      stop(){
        this.finish = true;
      },

      target() {
        if(this.finish || this.busy) return;

        this.$emit('target', {
          complete: () => { this.complete() },
          loaded: () => { this.loaded() },
          stop: () => { this.stop() }
        });

        this.busy = true;
      },

      checkScroll() {
        if (this.screen.bottom >= this.element.bottom)
          this.target()
      }
    }
  }
</script>
