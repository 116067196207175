var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mega-loading" }, [
    !_vm.finish && _vm.busy
      ? _c(
          "svg",
          {
            staticClass: "mega-loading-loader",
            attrs: { viewBox: "-2000 -1000 4000 2000" }
          },
          [
            _c("path", {
              attrs: {
                id: "inf",
                d:
                  "M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"
              }
            }),
            _vm._v(" "),
            _c("use", {
              attrs: {
                "xlink:href": "#inf",
                "stroke-dasharray": "1570 5143",
                "stroke-dashoffset": "6713px"
              }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.finish
      ? _c("span", { staticClass: "mega-loading-info" }, [
          _vm._v("No More Results :)")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }